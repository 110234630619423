import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import DateSvg from '../../../static/svg/dateSvg';

const WelcomeBanner2023 = () => {
  return (
    <div
      className="banner banner-style-5"
      style={{
        backgroundImage: "url('/images/welcome-offer/2023.jpg')",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xl-6">
            <div className="banner-content -welcome">
              <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                <h1 className="title">Usher in the New Year with us</h1>
                <span className="subtitle">
                  Sign up with InvoicePaid+ and receive <strong>SGD50</strong>&nbsp;shopping
                  vouchers*
                </span>
                <div className="date-wrap pb--70">
                  {DateSvg({})}
                  <div>
                    <p>Campaign Period</p>
                    <p className="-bold">9 January - 28 February 2023</p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBanner2023;
