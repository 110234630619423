import * as React from "react"

const DateSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    width={56.506}
    height={56.506}
    style={{
      enableBackground: "new 0 0 200 200",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M40 113.3h146.7v73.3H40v-73.3z"
      style={{
        fill: "#d81b23",
      }}
    />
    <path
      d="M190 20h-36.7v-3.3c0-5.5-4.5-10-10-10s-10 4.5-10 10V20H66.7v-3.3c0-5.5-4.5-10-10-10s-10 4.5-10 10V20H10C4.5 20 0 24.5 0 30v153.3c0 5.5 4.5 10 10 10h180c5.5 0 10-4.5 10-10V30c0-5.5-4.5-10-10-10zM46.7 40v3.3c0 5.5 4.5 10 10 10s10-4.5 10-10V40h66.7v3.3c0 5.5 4.5 10 10 10s10-4.5 10-10V40H180v33.3H20V40h26.7zM20 173.3v-80h160v80H20z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
)

export default DateSvg